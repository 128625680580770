import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import peopleData from '../../content/people.json';
import "../scss/pages/team.scss"

class Team extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			people: peopleData.people,			
		}
	}
  render() {
    const { location, data } = this.props
    const s3i1 = get(data, 's3i1.childImageSharp.sizes')
    const s3i2 = get(data, 's3i2.childImageSharp.sizes')
	console.log(this.props);
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Team" />
        <div>
			<section className="hero team" style={{backgroundImage: "url('/img/team-header.jpg')"}}>
				<div className="container pt-5 py-md-5">
					<div className="row short-top-hero align-items-end">
						<div className="col-md-8">
							<h1 className="h1 pt-5 pl-3 pl-md-5">An experienced team. <br class="d-none d-md-block" />A reputation for success <br class="d-none d-md-block" />and discretion.</h1>							
						</div>
						<div className="col-md-4 text-right">			
							<a className="mr-3" href=""><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></a>
							<a href=""><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>				
						</div>	
					</div>
				</div>
			</section>
			<section className="bg-black py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-white mt-5">
							<p >
							For four decades, we have represented both individual and corporate clients in difficult and complex cases at all levels of trial and appellate court. Our lawyers routinely appear before the Supreme Court of Canada, the Court of Appeal for Ontario, the Divisional Court, as well as superior and provincial trial courts across the province.</p>

							<p>GHW conducts trials and appeals across Canada. At the core of our practice is a focus on criminal law and related regulatory and administrative litigation.</p>

							<p>We are deeply committed to active membership in our community, through pro bono work and as contributors to the legal profession through mentorship, academic writing and teaching, and continuing legal education. </p>
						</div>
					</div>
				</div>
			</section>
			<section className="who-we-are bg-black text-white py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-12 mb-5">
							<h3 className="h3">Who we are</h3>
							<div className=" row">
								{this.state.people.map((person)=>(
									<Link to={"/profiles/"+person.slug} className="thumbbio col-6 col-md-3 px-0">
										<img src={"/img/people/"+person.slug+".jpg"} alt=""/>
										<div className="who-content px-4">
											<p>{person.name}<br/>
											<i>{person.title}</i></p>
											<div className="hover-link"><span className="btn-link text-white">Read more</span></div>
										</div>
									</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default Team

export const query = graphql`
  query TeamPageQuery {
    s3i1: file(name: { eq: "home-section3-image1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
	},
	s3i2: file(name: { eq: "home-section3-image2" }) {
		childImageSharp {
		  sizes(quality: 100) {
			...GatsbyImageSharpSizes_withWebp
		  }
		}
	  }
  }
`
